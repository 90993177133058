<template>
  <Fragment>
    <div v-if="emailSent" class="login-form">
      <h2 class="text-center">Enter OTP</h2>
      <validation-observer v-slot="validation" slim>
        <form @submit.prevent="validateOtp(validation)">
          <form-input v-model="otp" type="text" name="Email" rules="required " placeholder="Enter OTP Here" />
          <div class="module-large">
            <div class="error-message" v-if="errorMsg !== ''">{{errorMsg}} </div>
          </div>
          <div class="module-large">
            <button class="button block" type="submit">Reset Password</button>
          </div>
        </form>
      </validation-observer>
    </div>
    <div v-else class="login-form">
      <h2 class="text-center">Forgot Password</h2>
      <div class="module-large">
        <p>Enter the email associated with your account and we will send an email with instructions to reset your password.</p>
        <validation-observer v-slot="validation" slim>
          <form @submit.prevent="sendReset(validation)">
            <form-input v-model="email" type="email" name="Email" rules="required|email" placeholder="Email Address" />
            <div class="module-large">
              <button class="button block" type="submit">Send Email</button>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
    <div class="grid login-form">
      <div class="col-12 text-right">
        <a @click="$emit('click')">Login</a>
      </div>
      <div class="module col-12" :style="{ height: '32px' }">
        <transition name="fade">
          <div v-if="resetPasswordError" class="error-message">{{ resetPasswordError }}</div>
        </transition>
      </div>
    </div>
  </Fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import FormInput from "@/components/forms/fields/FormInput";
import { ValidationObserver } from "vee-validate";
// import { authenticationService } from "@/services";
import { restApi } from "@/http/http.config";
import { encodeWithParam, decode } from "@/filters";
export default {
  name: "ForgotPassword",
  components: { ValidationObserver, FormInput, Fragment },
  data() {
    return {
      email: "",
      resetPasswordError: "",
      emailSent: "",
      otp:"",
      errorMsg:'',
    };
  },
  watch: {
    email(newVal) {
      if (newVal.length > 0) {
        this.resetPasswordError = "";
      }
    },
  },
  methods: {
    async sendReset(validation) {
      try {
        if (validation.valid) {
          restApi.post(`employee/generateOTP`,encodeWithParam({username:this.email.toLowerCase()})).then((data)=>{
            if(data.data.success){
              this.emailSent = true;
            }
          })
        }
      } catch (error) {
        this.resetPasswordError = "Error sending email, contact DDI.";
      }
    },

    validateOtp(validation){
      if (validation.valid) {
        restApi.post(`employee/validateOTPWithToken`,encodeWithParam({username:this.email.toLowerCase(),otp:this.otp})).then((data)=>{
          this.otp="";
          this.errorMsg="";  

          if(data.data.success){
                let decryptToken=decode(data.data.otpToken)
                return this.$router.push({ name: "reset", query: { token: decryptToken.otpToken}})
              }
              else{
              this.errorMsg=data.data.message
            }
            })
          }

    }
  },
};
</script>
